<template>
  <v-row>
    <v-col lg="6" cols="12" class="pb-0 mt-3">
      <v-row>
        <v-col sm="6" cols="12" class="pt-0">
          <dashboard-card title="Quer realizar novo agendamento?">
            <v-btn
              :to="{ name: 'newAppointmentPatient' }"
              class="primary my-4"
              x-large
              rounded
            >
              Clique aqui
            </v-btn>
          </dashboard-card>
        </v-col>
        <v-col sm="6" cols="12" class="pt-0">
          <dashboard-card title="Terapias realizadas no APP">
            <v-progress-circular size="80" color="primary" value="100">
              <dashboard-text :text="countPerformedTherapies" />
            </v-progress-circular>
          </dashboard-card>
        </v-col>
      </v-row>
      <dashboard-card title="Próximos agendamentos" class="mt-3">
        <next-appointments-patient :appointments="nextAppointments" />
      </dashboard-card>
    </v-col>
    <v-col lg="6" cols="12">
      <dashboard-card title="Últimos rastreio realizados">
        <therapies-list
          :therapies="lastTherapies"
          field-name="therapistName"
          name-route-detailed="myTherapys"
        />
      </dashboard-card>
    </v-col>
  </v-row>
</template>

<script>
import {
  getPatientDashboardData,
  getTherapistDashboardData,
} from "@/services/external/dashboard.service";

export default {
  components: {
    DashboardCard: () =>
      import("@/domains/dashboard/presentation/components/DashboardCard"),
    DashboardText: () =>
      import("@/domains/dashboard/presentation/components/DashboardText"),
    TherapiesList: () =>
      import(
        "@/domains/dashboard/presentation/components/SimplifiedTherapiesList"
      ),
    NextAppointmentsPatient: () =>
      import(
        "@/domains/dashboard/presentation/components/NextAppointmentsPatient"
      ),
  },
  data: () => ({
    lastTherapies: [],
    countPerformedTherapies: 0,
    nextAppointments: [],
  }),
  created() {
    this.fillData();
  },
  methods: {
    async fillData() {
      const { data } = await getPatientDashboardData();
      const { lastTherapies, countPerformedTherapies, nextAppointments } = data;
      this.lastTherapies = lastTherapies;
      this.countPerformedTherapies = countPerformedTherapies;
      this.nextAppointments = nextAppointments;
    },
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped></style>
